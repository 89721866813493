<template>
  <div>
    <van-nav-bar
      title="7天坐标"
      left-arrow
      class="navbar"
      @click-left="goBack"
    />
    <div class="SevenDay">
      <div class="coordinates">
        <div class="ctx-title">
          <div class="title-ctx">请查看系统自动生成的情绪坐标图</div>
          <div class="title-fk"></div>
          <div class="ctx-title2">
            它呈现了你这几天大致的情绪状态和“分布”，你可以用它作为参考来了解这段时间自己的情绪体验和变化
          </div>
        </div>
        <div class="zuobiao">
          <div
            class="emo-coordinate"
            v-for="(coordinate, index) in coordinates"
            :key="index"
            :style="{
              left: coordinate.x - 7 + 'px',
              top: coordinate.y - 7 + 'px',
            }"
          >
            <img
              src="https://lesson.iapeap.com/images/EmotionalValue/wujiaoxing.png"
              alt="Emo Coordinate"
              width="14"
              height="14"
            />
          </div>
        </div>
        <div class="wenxintishi">
          <div class="wenxintishi-ctx1">
            <img
              src="https://lesson.iapeap.com/images/EmotionalValue/gantanhao.png"
              alt=""
            />
            <div>温馨提示：</div>
          </div>
          <div class="wenxintishi-ctx2">
            你可以在第7日的情绪日记页面，通过
            <span>显示7日坐标</span> 按钮再次查看
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEmoPoint } from "../../api/login";

export default {
  data() {
    return {
      coordinates: [],
    };
  },
  created() {
    this.getEmoPoint();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getEmoPoint() {
      let data = {
        userId: window.localStorage.getItem("userId"),
        stage: window.localStorage.getItem("stage"),
      };
      getEmoPoint(data)
        .then((res) => {
          // Assuming res is an object with the "data" property containing an array
          if (Array.isArray(res.data)) {
            this.coordinates = res.data.map((item) => {
              const [x, y] = item.emo_coordinate.split(",").map(Number);
              return { x, y };
            });
          } else {
            console.error(
              "Error: Emo point data is not in the expected format."
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching emo point data:", error);
        });
    },
  },
};
</script>

<style lang="less">
.navbar {
  background-color: #ededed;
}
/deep/ .van-nav-bar .van-icon {
  color: #464646;
}
.SevenDay {
  background-color: #f4fafe;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}
.ctx-title {
  .title-ctx {
    z-index: 999;
    position: relative;
    font-size: 18px;
    font-weight: 500;
    color: #000025;
    line-height: 25px;
  }
  .title-fk {
    width: 35px;
    height: 5px;
    background: #629ee9;
    position: relative;
    bottom: 8px;
    z-index: 998;
    transform: skew(-25deg);
  }
}
.ctx-title2 {
  width: 297px;
  height: 54px;
  font-size: 13px;
  font-weight: 400;
  color: #000025;
  line-height: 18px;
}
.coordinates {
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  .zuobiao {
    margin-top: 10px;
    height: 298px;
    background-image: url("https://lesson.iapeap.com/images/EmotionalValue/zuobiao.png");
    background-size: 100% 100%;
    position: relative; /* Required for absolute positioning of .emo-coordinate */
  }
  .emo-coordinate {
    position: absolute;
    width: 50px;
    height: 50px;
  }
  .wenxintishi {
    margin-top: 10px;
    .wenxintishi-ctx1 {
      display: flex;
      align-items: center;
      gap: 5px;
      img {
        width: 12px;
      }
    }
    .wenxintishi-ctx2 {
      padding: 0 10px;
      font-size: 10px;
      margin-bottom: 8px;
      color: #a09c9b;
      box-sizing: border-box;
      span {
        width: 58px;
        height: 14px;
        background: #72adf4;
        border-radius: 100px;
        color: #fff;
        font-size: 12px;
        padding: 2px;
      }
    }
  }
}
</style>
